<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md6.xs12
                  v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="nome", type="text", v-model='auditado.nome')
                v-flex.md6.xs12
                  v-autocomplete(label="Selecione o tipo de identificador", :items="listTiposIdentificadores", :disabled="!listTiposIdentificadores.length", item-text="nome", item-value="id", color="gray", v-model="auditado.tipo_identificador_id", :loading="loadingTiposIdentificadores", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
              v-layout(row, wrap)
                v-flex.md6.xs12
                  v-text-field(label="Identificador", color="gray", required, v-validate="'required'", :error-messages="errors.collect('identificador')", data-vv-name="identificador", data-vv-as="identificador", type="text", v-model='auditado.identificador')
                v-flex.md6.xs12
                  v-text-field(label="Nome do responsável", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome_responsavel')", data-vv-name="nome_responsavel", data-vv-as="nome_responsavel", type="text", v-model='auditado.nome_responsavel')
              v-layout(row, wrap)
                v-flex.md6.xs12
                  v-text-field(label="Email do responsável", color="gray", required, v-validate="'required'", :error-messages="errors.collect('email_responsavel')", data-vv-name="email_responsavel", data-vv-as="email_responsavel", type="text", v-model='auditado.email_responsavel')
                v-flex.md6.xs12
                  v-text-field(label="Telefone do responsável", color="gray", required, v-validate="'required'", :error-messages="errors.collect('telefone_responsavel')", data-vv-name="telefone_responsavel", data-vv-as="telefone_responsavel", type="text", v-model='auditado.telefone_responsavel')
              v-layout(row, wrap)
                v-flex.xs12.text-xs-right
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    loadingTiposIdentificadores: false,
    listTiposIdentificadores : [],
    auditado: {},
  }),
  mounted () {
    const vm = this;
    if (vm.$route.params.id) {
      vm.getAuditado(vm.$route.params);
    }
    vm.getTiposIdentificadores();
  },
  methods: {
    async save (auditado) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/auditado', auditado);
        auditado.id = response.data.id;
        vm.$router.push({ name: 'AuditadoLista', params: { id: response.data.id } });
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Auditado salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o auditado. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (auditado) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/auditado/${auditado.id}`, auditado);
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Auditado salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar o auditado. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.auditado.id ? 'update' : 'save'](vm.auditado);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getAuditado (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/auditado/${params.id}`);
        vm.auditado = response.data || {};
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async getTiposIdentificadores () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/tipo-identificador');
        vm.listTiposIdentificadores = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(item.nome);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    }
  },
};
</script>

<style scoped>

</style>

